.main {
    color: var(--green);
    font-size: 40px;
    width: 100%;
    height: 100%;
}

.container {
    padding: 50px;
}
