.main {
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;

    /* you dont need the stuff below, but its what I used to create the loading circle */
    border: 3px solid #999;
    border-radius: 30px;
    height: 34px;
    width: 34px;
    position: relative;
    display: inline-block;
    margin-top: 5px;
    text-align: center;
}

@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0;
    }
}
