/* @import url(http://fonts.googleapis.com/earlyaccess/alefhebrew.css); */

:root {
    --primary-bg: #f2f2f2;
    --secondary-bg: #fff;
    --text: #272f33;
    --button-color: #f2f2f2;
    --active-bg-color: #178df7;
    --active-text-color: #fff;
    --top-bar-bg: #272f33;
    --text-accent: #4e87dc;
}

.darkMode {
    --primary-bg: #545454;
    --secondary-bg: #5a5a5a;
    --text: #fff;
    --button-color: #676767;
    --active-bg-color: #2b2b2b;
    --active-text-color: #fff;
    --top-bar-bg: #424242;
    --text-accent: #4e87dc;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

body,
input,
textarea {
    font-family: 'Carmela', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body .lang-rtl,
.lang-rtl input,
.lang-rtl textarea {
    font-family: 'Carmela', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root,
.root-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    /* justify-content: center; */
    /* background: url('https://www.gstatic.com/mobilesdk/190403_mobilesdk/project_create_illo_end@2x.png'); */
    /* background-repeat: no-repeat; */
    /* background-position: bottom right; */
}

body {
    padding: 0;
    margin: 0;
}
