.main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;

    .buttonGroup {
        direction: ltr;
        text-align: start;
    }

    .fileInput {
        width: 100%;
    }
}

.container {
    width: 500px;
}

.filePicker {
    margin-block-start: 20px;
}

.separator {
    display: inline-block;
    margin-block-start: 20px;
}

.userIcon {
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.name {
    margin-top: 30px;
    width: 220px;
}
