.main {
    display: inline-block;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid white;
    vertical-align: middle;
    color: #ffffff;
    text-transform: capitalize;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // margin-inline-start: 10px;
    & + .main {
        // margin-inline-end: 10px;
    }
    &.outOfDate {
        opacity: 0.2;
    }
    &.isPending {
        border: none;
    }
}
