.input {
    border: 1px solid rgba(114, 114, 114, 0.521);
    border-radius: 5px;
    height: 36px;
    width: 100%;
    font-size: 16px;
    padding-inline-start: 5px;
    &::-webkit-input-placeholder {
        color: rgb(167, 167, 167);
    }
}

.readOnly {
    cursor: default;
}