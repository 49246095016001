$domain: 'https://remote-quiz-1.s3.us-east-1.amazonaws.com';

@font-face {
    font-family: 'Carmela';
    src: url($domain + '/fonts/carmela/carmela.eot'); /* IE9 Compat Modes */
    src: url($domain + '/fonts/carmela/carmela.woff') format('woff') /* Pretty Modern Browsers */,
        url($domain + '/fonts/carmela/carmela.ttf') format('truetype') /* Safari, Android, iOS */,
        url($domain + '/fonts/carmela/carmela.svg#svgFontName') format('svg'); /* Legacy iOS */
}

:root {
    --green: rgb(37, 158, 37);
    --green-opac: rgba(255, 255, 255, 0.2);
    --light-green: rgb(170, 200, 170);
    --pink: pink;
    --lightblue: lightblue;
    --yellow: yellow;
}

.container-part {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.65);
    position: relative;
    overflow: hidden;
    color: var(--green);
    padding-bottom: 30px;
    &:after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 30px;
        background: linear-gradient(
            0deg,
            rgba(43, 85, 21, 1) 0%,
            rgba(43, 85, 21, 0.5203256302521008) 54%,
            rgba(43, 85, 21, 0.10968137254901966) 100%
        );
    }
}

.fullPageDialog {
    direction: rtl;
}

.bg-pub {
    width: 100%;
    height: 100%;
    background-color: #210040;
    background-image: url('https://remote-quiz-1.s3.us-east-1.amazonaws.com/images/bgg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fancy-scroll {
    /* custom scrollbar */
    &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        display: block !important;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 10px;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: rgba(214, 222, 225, 0.506);
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #d6dee1;
    }
}
