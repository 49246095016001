.main {
    color: var(--green);
    font-size: 40px;
    padding: 60px 30px;
    width: 100%;
    height: 100%;
}

.container {
    padding: 50px;
    height: 100%;
    width: 100%;
}
