.main {
    direction: ltr;
    width: 700px;
    height: 300px;
    * {
        box-sizing: border-box;
    }
    --forecolor: white;
    &.darkText {
        --forecolor: black;
    }
}
:global(.lang-rtl) {
    .main {
        direction: rtl;
    }
}

.deviceSelect {
    width: 100%;
    overflow: hidden;
}
.deviceSelect.deviceSelect {
    color: var(--forecolor) !important;
    margin-inline-start: 20px;
    :global(.MuiSelect-icon) {
        color: var(--forecolor) !important;
    }
    &:before,
    &:after {
        border-bottom-color: var(--forecolor);
    }
}

.cameraFeed {
    grid-area: camera;
}
.cameraSelector {
    grid-area: video;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}
.audioSelector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: audio;
    padding: 15px;
}

.outerGrid {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
        'video camera'
        'audio camera';
}
.type {
    font-size: 20px;
}
.volumeMeter {
    display: flex;
    justify-content: center;
    width: 100% !important;
    opacity: 0.5;
    margin-top: 10px;
}

.noCamera,
.cameraActualFeed {
    width: 100%;

    outline: 2px solid var(--forecolor);
}
.noCamera {
    height: 80%;
    background: black;
}
.success {
    background: rgba(144, 238, 144, 0.507);
}

.intermediate {
    background: rgba(255, 255, 0, 0.431);
}

.error {
    background: rgba(255, 0, 0, 0.445);
}

.status {
    padding: 20px;
    label {
        font-size: 18px;
    }
}

.enumerator {
    width: 100%;
    overflow: hidden;
}

.noVolume {
    height: 20px;
    width: 100%;
}
