.main {
    display: flex;
    align-items: center;

    .input {
        display: none;
    }

    .image {
        width: 50px;
        height: 50px;
        cursor: pointer;
    }
}
